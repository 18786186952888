<template>
  <div id="fullscreen_container">
    <a href="/all_styles.jpg" class="fullscreen_container_download" download="All_styles.jpg">
      <icon name="download" scale="1.3"></icon><span class="ml-50">Download</span>
    </a>
    <div class="fullscreen_container_close" v-on:click="$emit('close')">
      <icon name="times" scale="1.8"></icon>
    </div>
    <div class="fullscreen_container-content">
      <img
        :src="imgUrl"
        alt=""
        ref="previewer"
        class="fullscreen_container-image"
        v-bind:style="{
          top: positionTop + '%',
          left: positionLeft + '%',
          transform: 'translate(-50%, -50%) scale(' + zoom.current + ')',
        }"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "imgPreviewer",
  props: ["imgUrl"],
  data() {
    return {
      mousedown: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,

      cursorY: window.innerHeight / 2,
      cursorX: window.innerWidth / 2,
      startCursorY: window.innerHeight / 2,
      startCursorX: window.innerWidth / 2,
      endCursorY: window.innerHeight / 2,
      endCursorX: window.innerWidth / 2,

      diffY: 0,
      diffX: 0,

      zoom: {
        current: 1,
        min: 1,
        max: 5,
        switch: 0.2,
      },
    };
  },
  computed: {
    positionTop() {
      return (this.cursorY * 100) / this.windowHeight;
    },
    positionLeft() {
      return (this.cursorX * 100) / this.windowWidth;
    },
  },
  methods: {
    handleMousedown(e) {
      if (!e.touches) {
        e.preventDefault();
      }
      this.mousedown = true;
      this.startCursorX = "ontouchstart" in window ? e.touches[0].clientX : e.clientX;
      this.startCursorY = "ontouchstart" in window ? e.touches[0].clientY : e.clientY;
    },
    handleMouseup(e) {
      this.mousedown = false;
      this.endCursorY = this.cursorY;
      this.endCursorX = this.cursorX;
    },
    handleMousemove(e) {
      if (!this.mousedown) {
        return;
      }
      let X = "ontouchstart" in window ? e.touches[0].clientX : e.clientX;
      let Y = "ontouchstart" in window ? e.touches[0].clientY : e.clientY;
      this.cursorY = this.endCursorY - (this.startCursorY - Y);
      this.cursorX = this.endCursorX - (this.startCursorX - X);
    },
    handleWheel(e) {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);
      if (Math.sign(e.deltaY) === 1) {
        if (this.zoom.current > this.zoom.min) {
          this.zoom.current = this.zoom.current - this.zoom.switch;
        }
      } else {
        if (this.zoom.current <= this.zoom.max) {
          this.zoom.current = this.zoom.current + this.zoom.switch;
        }
      }
    },
  },
  created() {},
  mounted() {
    if (!this.$isServer) {
      if ("ontouchstart" in window) {
        this.$refs.previewer.addEventListener("touchstart", this.handleMousedown);
        this.$refs.previewer.addEventListener("touchend", this.handleMouseup);
        this.$refs.previewer.addEventListener("touchmove", this.handleMousemove);
        this.$refs.previewer.addEventListener("wheel", this.handleWheel);
      } else {
        this.$refs.previewer.addEventListener("mousedown", this.handleMousedown);
        this.$refs.previewer.addEventListener("mouseup", this.handleMouseup);
        this.$refs.previewer.addEventListener("mousemove", this.handleMousemove);
        this.$refs.previewer.addEventListener("wheel", this.handleWheel);
      }
    }
  },
  destroyed() {
    if (!this.$isServer) {
      if ("ontouchstart" in window) {
        this.$refs.previewer.removeEventListener("touchstart", this.handleMousedown);
        this.$refs.previewer.removeEventListener("touchend", this.handleMouseup);
        this.$refs.previewer.removeEventListener("touchmove", this.handleMousemove);
        this.$refs.previewer.removeEventListener("wheel", this.handleWheel);
      } else {
        this.$refs.previewer.removeEventListener("mousedown", this.handleMousedown);
        this.$refs.previewer.removeEventListener("mouseup", this.handleMouseup);
        this.$refs.previewer.removeEventListener("mousemove", this.handleMousemove);
        this.$refs.previewer.removeEventListener("wheel", this.handleWheel);
      }
    }
  },
};
</script>

<style lang="sass">
#fullscreen_container
	display: block
	position: fixed
	top: 0
	right: 0
	bottom: 0px
	left: 0
	background-color: rgba(100, 118, 135, 0.5)
	z-index: 999
	& .fullscreen_container-content
		display: block
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		& .fullscreen_container-image
			display: block
			position: absolute
			width: 90%
			height: auto
			transform: translate(-50%, -50%) scale(1)
	& a.fullscreen_container_download
		display: block
		position: absolute
		top: 0px
		left: 50%
		padding: 0.75rem 0.75rem
		color: #fff
		font-size: 1.3rem
		cursor: pointer
		transition: all 0.3s
		transform: translateX(-50%)
		z-index: 999
		&:hover
			color: #eee

	& .fullscreen_container_close
		display: block
		position: absolute
		top: 0px
		right: 0px
		padding: 0.75rem 0.75rem
		color: #fff
		cursor: pointer
		transition: all 0.3s
		z-index: 999
		&:hover
			color: #eee
</style>
